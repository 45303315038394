<template>
  <div class="row m-0">
    <div class="col-sm-12 mt-2 mb-2">
      <el-dropdown v-if="checkSelect" class="dropdown-check-table">
        <el-button type="primary">
          ({{ checkSelect }}) items<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu class="dropdown-status-table" slot="dropdown" v-if="actionsTable && actionsTable.length">
          <el-dropdown-item v-for="(actionTable, index) of actionsTable" @click="actionTable.callback(multipleSelection)" :key="'action' + index">
            <el-button
              @click="actionTable.callback(multipleSelection)"
              type="text"
              class="full-width text-left"
              :key="'btn' + index"
            >{{ actionTable.title }}</el-button>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <el-table class="table-striped"
                :data="queriedData"
                border
                style="width: 100%"
                @selection-change="handleSelectionChange">
        >
        <el-table-column
          type="selection"
          width="45"
          v-if="checkCheckbox"
          fixed="left"
          class-name="bg-white"
        >
        </el-table-column>
        <el-table-column v-for="column in tableColumns"
                         :key="column.label"
                         :min-width="column.minWidth"
                         :prop="column.prop"
                         :label="column.label">
           <template slot-scope="props">
             <span v-if="column.type === 'image'">
               <img :src="props.row[column.prop]" />
             </span>
             <span v-else-if="column.type === 'number'">
              {{props.row[column.prop] ? formatNumber(props.row[column.prop]) : props.row[column.prop]}}
             </span>
             <span v-else-if="column.type === 'datetime'">
              {{ props.row[column.prop] ? $util.dateTimeFormat(props.row[column.prop]) : props.row[column.prop] }}
             </span>
             <span v-else-if="column.type === 'distance'">
              {{props.row[column.prop] ? formatNumber(Math.round(props.row[column.prop] / 1000 * 100) / 100) : props.row[column.prop]}}
             </span>
             <span v-else-if="column.type === 'moving_time'">
              {{ props.row[column.prop] ? convertSecondsToHours(props.row[column.prop]) : props.row[column.prop] }}
             </span>
             <span v-else-if="column.type === 'badge'">
               <badge v-if="props.row[column.prop] === 'new'" type="primary">Mới</badge>
               <badge v-else-if="props.row[column.prop] === 'confirm'" type="info">Xác nhận</badge>
               <badge v-else-if="props.row[column.prop] === 'done'" type="success">Hoàn thành</badge>
               <badge v-else-if="props.row[column.prop] === 'cancel'" type="danger">Hủy</badge>
               <badge v-else-if="props.row[column.prop] === 'return'" type="danger">Hoàn trả</badge>
               <badge v-else-if="props.row[column.prop] === 'draft'" type="default">Nháp</badge>
               <badge v-else-if="props.row[column.prop] === 'Đã thanh toán'" type="success">Đã thanh toán</badge>
               <badge v-else-if="props.row[column.prop] === 'Chưa thanh toán'" type="warning">Chưa thanh toán</badge>
               <badge v-else-if="props.row[column.prop] === 'completed'" type="success">Hoàn thành</badge>
               <badge v-else-if="props.row[column.prop] === 'joined'" type="info">Chưa hoàn thành</badge>
               <badge v-else-if="column.prop == 'addons' && props.row[column.prop] == 0" type="default">Không</badge>
               <badge v-else-if="column.prop == 'addons' && props.row[column.prop] == 1" type="success">Có</badge>
               <badge v-else-if="column.prop == 'shipping_status' && props.row[column.prop] == 0" type="default">Chưa giao hàng</badge>
               <badge v-else-if="column.prop == 'shipping_status' && props.row[column.prop] == 1" type="default">Nhận tại cửa hàng</badge>
               <badge v-else-if="column.prop == 'shipping_status' && props.row[column.prop] == 2" type="primary">Đã nhận trực tiếp</badge>
               <badge v-else-if="column.prop == 'shipping_status' && props.row[column.prop] == 3" type="primary">Viettel Post</badge>
               <badge v-else-if="column.prop == 'shipping_status' && props.row[column.prop] == 4" type="primary">VNPost</badge>
               <badge v-else-if="column.prop == 'shipping_status' && props.row[column.prop] == 5" type="primary">Giao hàng nhanh</badge>
               <badge v-else-if="column.prop == 'shipping_status' && props.row[column.prop] == 6" type="primary">EMS</badge>
               <badge v-else-if="column.prop == 'shipping_status' && props.row[column.prop] == 7" type="primary">DHL</badge>
               <badge v-else-if="column.prop == 'shipping_status' && props.row[column.prop] == 8" type="primary">Giao hàng tiết kiệm</badge>
               <badge v-else-if="column.prop == 'is_deleted' && props.row[column.prop] == 0" type="success">Hiện</badge>
               <badge v-else-if="column.prop == 'is_deleted' && props.row[column.prop] == 1" type="danger">Ẩn</badge>
               <badge v-else-if="column.prop == 'paces'" type="danger" v-for="(item, index) of props.row[column.prop]" :key="index" style="margin-right: 3px; margin-bottom: 3px; display: inline-block;">{{item}}</badge>
               <badge v-else-if="column.prop == 'donate_status' && props.row[column.prop] == 0" type="warning">Chưa thanh toán</badge>
               <badge v-else-if="column.prop == 'donate_status' && props.row[column.prop] == 1" type="success">Đã thanh toán</badge>
               <badge v-else-if="column.prop == 'status' && props.row[column.prop] == 0" type="warning">Chưa thanh toán</badge>
               <badge v-else-if="column.prop == 'status' && props.row[column.prop] == 1" type="success">Đã thanh toán</badge>
               <badge v-else-if="props.row[column.prop] == 0" type="warning">Không sử dụng</badge>
               <badge v-else-if="props.row[column.prop] == 1" type="info">Đang sử dụng</badge>
               <badge v-else-if="props.row[column.prop] === 'active'" type="info">Đang hiện</badge>
               <badge v-else-if="props.row[column.prop] === 'inactive'" type="warning">Đang ẩn</badge>
               <badge v-else-if="props.row[column.prop] === 'add'" type="success">Tạo mới</badge>
               <badge v-else-if="props.row[column.prop] === 'update'" type="info">Cập nhật</badge>
               <badge v-else-if="props.row[column.prop] === 'delete'" type="danger">Xóa</badge>
               <span v-else>{{props.row[column.prop]}}</span>
             </span>
             <span v-else>
               <a v-if="column.link && column.external && column.prop == 'order_id'" :href="'//' + column.link + '/' + props.row['order_id']" target="_blank">
                 {{props.row[column.prop]}}
               </a>
               <a v-else-if="column.link && column.external && column.prop == 'user_id'" :href="'//' + column.link + '/' + props.row['user_id']" target="_blank">
                 {{props.row[column.prop]}}
               </a>
               <a v-else-if="column.link && column.external && column.prop == 'detail_id'" :href="'//' + column.link + '/' + props.row['detail_id']" target="_blank">
                 {{props.row[column.prop]}}
               </a>
               <a v-else-if="column.type == 'link'" :href="props.row[column.prop]" target="_blank">
                 {{props.row[column.prop]}}
               </a>
               <div v-else-if="column.object == 'activity'">
                  <router-link v-if="props.row['object'] == 'race'" :to="'/races/' + props.row['object_id']">
                    {{props.row[column.prop]}}
                  </router-link>
                  <router-link v-else-if="props.row['object'] == 'order'" :to="'/orders/' + props.row['object_id']">
                    {{props.row[column.prop]}}
                  </router-link>
                  <router-link v-else-if="props.row['object'] == 'user'" :to="'/users/' + props.row['object_id']">
                    {{props.row[column.prop]}}
                  </router-link>
                </a>
               </div>
               <span v-else>{{props.row[column.prop]}}</span>
             </span>
           </template>
        </el-table-column>
        <el-table-column
          :min-width="130"
          fixed="right"
          class-name="td-actions"
          label="Tác vụ"
          v-if="actions && actions.length"
          >
          <template slot-scope="props">
            <p-button
              :key="index"
              :type="action.type" size="sm" icon @click="action.callback(props.$index, props.row)"
              v-for="(action, index) in actions"
            >
              <i :class="action.icon"></i>
            </p-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="col-sm-4">
      <el-select
        class="select-default"
        v-model="pagination.perPage"
        placeholder="Per page">
        <el-option
          class="select-default"
          v-for="item in pagination.perPageOptions"
          :key="item"
          :label="item + ' nội dung/trang'"
          :value="item">
        </el-option>
      </el-select>
    </div>
    <div class="col-sm-4 pagination-info">
      <p class="text-entries text-center">Từ #{{from + 1}} đến #{{to}} trên tổng số {{total}}</p>
    </div>
    <div class="col-sm-4">
      <p-pagination class="pull-right"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="pagination.total"
                    :check-paginate="checkPaginate()"
                    :paginate-type="paginateType()">
      </p-pagination>
    </div>
  </div>
</template>
<script>
  import {Table, TableColumn, Select, Option, Dropdown, Button, DropdownMenu, DropdownItem} from 'element-ui'
  import Badge from './Badge.vue';
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {mapState} from 'vuex'

  export default{
    props: ['actions', 'columnDefs', 'dataRows', 'paginate', 'hasCheckbox', 'actionsTable'],
    components: {
      ElTable: Table,
      ElTableColumn: TableColumn,
      ElSelect: Select,
      ElOption: Option,
      ElDropdown: Dropdown,
      ElButton: Button,
      ElDropdownMenu: DropdownMenu,
      ElDropdownItem: DropdownItem,
      PPagination,
      Badge
    },
    computed: {
      ...mapState({
        pageTitle: 'pageTitle'
      }),
      pagedData () {
        if (this.paginate && this.paginate.data) return this.tableData;
        return this.tableData.slice(this.from, this.to)
      },

      tableData() {
        return this.dataRows;
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData () {
        if (!this.searchQuery) {
          this.pagination.total = this.tableData.length
          return this.pagedData
        }
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              let rowValue = row[key].toString()
              if (rowValue.includes && rowValue.includes(this.searchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total() {
        if (this.paginate && this.paginate.total) {
          this.pagination.total = this.paginate.total;
          return this.paginate.total;
        }
        this.pagination.total = this.tableData.length
        return this.tableData.length
      },
      checkSelect() {
        return this.multipleSelection.length;
      }
    },
    data () {
      return {
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: this.columnDefs.map(col => col.prop),
        tableColumns: this.columnDefs,
        multipleSelection: [],
        checkCheckbox: this.actionsTable && this.actionsTable.length
      }
    },
    methods: {
      checkAction() {
        if (!this.actionsTable || !this.actionsTable.length) return false;
        return this.actionsTable.length;
      },
      handleLike (index, row) {
        alert(`Your want to like ${row.name}`)
      },
      handleEdit (index, row) {
        alert(`Your want to edit ${row.name}`)
      },
      handleDelete (index, row) {
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },
      formatNumber(num) {
        if (num) return num.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        return num;
      },
      convertSecondsToHours(seconds) {
        var date = new Date(null);
        date.setSeconds(seconds);
        var result = date.toISOString().substr(11, 8);
        return result;
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      checkPaginate() {
        if (this.paginate && this.paginate.total) return true;
        return false;
      },
      paginateType() {
        if (this.paginate && this.paginate.total && this.paginate.type) return this.paginate.type;
        return '';
      }
    },
    mounted() {
    }
  }
</script>

<style lang="scss">
  .el-table .td-actions {
    background-color: white;
    button.btn {
      margin-right: 5px;
    }
  }
  .text-entries {
    line-height: 46px;
  }
  .dropdown-check-table {
    position: absolute;
    top: 8px;
    left: 50px;
    z-index: 10;
  }
</style>
